import { Http, HttpAuth } from '../../config/Http'
import { changeLoading } from './loading.action'
import { changeNotify } from './notify.action'

export const actionTypes = {
    CHANGEDOUBLE: 'MENSAGEM_CHANGEDOUBLE',
    CHANGECRASH: 'MENSAGEM_CHANGECRASH',
    CHANGEROLETA: 'MENSAGEM_CHANGEROLETA',
    ERROR: 'MENSAGEM_ERROR',
    SUCCESS: 'MENSAGEM_SUCCESS',
    SHOW:'MENSAGEM_SHOW',
    INDEX:'MENSAGEM_INDEX',
    CHANGEPREMIUM:'MENSAGEM_CHANGEPREMIUM',
    CHANGEFANTAN:'MENSAGEM_CHANGEFANTAN',
    CHANGEFUTBALLSTUDIO:'MENSAGEM_CHANGEFUTBALLSTUDIO',
    CHANGEPENALTY:'MENSAGEM_CHANGEPENALTY',
    CHANGEMINER:'MENSAGEM_CHANGEMINER',
    CHANGEFORTUNETIGER:'MENSAGEM_CHANGEFORTUNETIGER',
    CHANGENOVAROLETA: 'MENSAGEM_CHANGENOVAROLETA',
    CHANGEVARIOSLOT: 'MENSAGEM_CHANGEVARIOSLOT',
    CHANGEROLETASESSAO:'ESTRATEGIA_CHANGEROLETASESSAO',
    CHANGEROLETANOVAIASESSAO:'MENSAGEM_CHANGEROLETASESSAO',
}

export const changeroletaiasessao= (payload) => ({
    type: actionTypes.CHANGEROLETANOVAIASESSAO,
    payload
})
export const changeroletasessao= (payload) => ({
    type: actionTypes.CHANGEROLETASESSAO,
    payload
})

export const changedouble = (payload) => ({
    type: actionTypes.CHANGEDOUBLE,
    payload
})
export const changecrash = (payload) => ({
    type: actionTypes.CHANGECRASH,
    payload
})
export const changepremium = (payload) => ({
    type: actionTypes.CHANGEPREMIUM,
    payload
})
export const changefantan = (payload) => ({
    type: actionTypes.CHANGEFANTAN,
    payload
})
export const changefutballstudio = (payload) => ({
    type: actionTypes.CHANGEFUTBALLSTUDIO,
    payload
})
export const changepenalty = (payload) => ({
    type: actionTypes.CHANGEPENALTY,
    payload
})
export const changeminer = (payload) => ({
    type: actionTypes.CHANGEMINER,
    payload
})
export const changefortunetiger = (payload) => ({
    type: actionTypes.CHANGEFORTUNETIGER,
    payload
})



export const changeroleta = (payload) => ({
    type: actionTypes.CHANGEROLETA,
    payload
})

export const changenovaroleta = (payload) => ({
    type: actionTypes.CHANGENOVAROLETA,
    payload
})

export const changevarioslot = (payload) => ({
    type: actionTypes.CHANGEVARIOSLOT,
    payload
})

export const error = (payload) => ({
    type: actionTypes.ERROR,
    payload
})

export const success = (payload) => ({
    type: actionTypes.SUCCESS,
    payload
})



export const indexResponse = (payload) => ({
    type: actionTypes.INDEX,
    payload
})

export const showResponse =(payload) => ({
    type: actionTypes.SHOW,
    payload,
})

export const index = () => dispatch => {
    return HttpAuth.get('/grupo')
    .then(res => typeof res !== 'undefined' && dispatch(indexResponse(res.data)))
}


//ROLETA SESSAO
export const showRoletaSessao = (id) => dispatch => {
    return HttpAuth.get('/mensagem/showroletasessao/'+id)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}

export const updateRoletaSessao = (id,data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Mensagem'
    }))

    return HttpAuth.put('/mensagem/updateroletasessao/'+id,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
           })
}


export const store = data => dispatch => {
    dispatch(changeLoading({
        open: true,
        msg: 'Cadastrando usuário...'
    }));
  
    return HttpAuth.post('/grupo', data)
        .then(res => {
            
            dispatch( changeLoading({open: false}) )
            if(typeof res !== 'undefined'){
                if(res.data.error){
                    dispatch(success(false));
                    dispatch(error(res.data.error));
                }
   
                if(res.status === 201){
                    dispatch(success(true));
                    dispatch( changeNotify({
                        open: true,
                        class: 'success',
                        msg: res.data.msg
                    }) )
                   
                }
            }
        })
        .catch(error => {
        
            dispatch( changeLoading({open: false}) )

            if(error.response) {
                dispatch(error(error.response.data.error))
            }
        })
}

export const updatedouble = (id,data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Mensagem'
    }))

    return HttpAuth.put('/mensagem/updatedouble/'+id,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
           })
}

export const updatefantan = (id,data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Mensagem'
    }))

    return HttpAuth.put('/mensagem/updatefantan/'+id,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
           })
}

export const updatefutballstudio = (id,data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Mensagem'
    }))

    return HttpAuth.put('/mensagem/updatefutballstudio/'+id,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
           })
}

export const updatecrash = (id,data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Mensagem'
    }))

    return HttpAuth.put('/mensagem/updatecrash/'+id,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
           })
}

//Mensagem premium
export const updatepremium = (id,data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Mensagem'
    }))

    return HttpAuth.put('/mensagem/updatepremium/'+id,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
           })
}

export const showpremium = (id) => dispatch => {
    return HttpAuth.get('/mensagem/showpremium/'+id)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}

export const updateroleta = (id,data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Mensagem'
    }))

    return HttpAuth.put('/mensagem/updateroleta/'+id,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
           })
}

export const showdouble = (id) => dispatch => {
    return HttpAuth.get('/mensagem/showdouble/'+id)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}

export const showfantan = (id) => dispatch => {
    return HttpAuth.get('/mensagem/showfantan/'+id)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}
export const showfutballstudio = (id) => dispatch => {
    return HttpAuth.get('/mensagem/showfutballstudio/'+id)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}

export const showcrash = (id) => dispatch => {
    return HttpAuth.get('/mensagem/showcrash/'+id)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}

export const showroleta = (id) => dispatch => {
    return HttpAuth.get('/mensagem/showroleta/'+id)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}


//NOVAROLETAIASESAOO
export const shownovaroletaIaSesao = (id) => dispatch => {
    return HttpAuth.get('/mensagem/shownovaroletaiasessao/'+id)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}

export const updatenovaroletaIaSessao = (id,data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Mensagem'
    }))

    return HttpAuth.put('/mensagem/updatenovaroletaiasesaao/'+id,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
           })
}

//NOVAROLETA
export const shownovaroleta = (id) => dispatch => {
    return HttpAuth.get('/mensagem/shownovaroleta/'+id)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}

export const updatenovaroleta = (id,data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Mensagem'
    }))

    return HttpAuth.put('/mensagem/updatenovaroleta/'+id,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
           })
}

//Penalty
export const showpenalty = (id) => dispatch => {
    return HttpAuth.get('/mensagem/showpenalty/'+id)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}

export const updatepenalty = (id,data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Mensagem'
    }))

    return HttpAuth.put('/mensagem/updatepenalty/'+id,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
           })
}

//Miner
export const showminer = (id) => dispatch => {
    return HttpAuth.get('/mensagem/showminer/'+id)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}

export const updateminer = (id,data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Mensagem'
    }))

    return HttpAuth.put('/mensagem/updateminer/'+id,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
           })
}

//FortuneTiger
export const showfortunetiger = (id) => dispatch => {
    return HttpAuth.get('/mensagem/showfortunetiger/'+id)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}

export const updateortunetiger = (id,data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Mensagem'
    }))

    return HttpAuth.put('/mensagem/updatefortunetiger/'+id,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
           })
}



//FortuneTiger
export const showVarioSlot = (id) => dispatch => {
    return HttpAuth.get('/mensagem/showvarioslot/'+id)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}

export const updateVarioSlot = (id,data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Mensagem'
    }))

    return HttpAuth.put('/mensagem/updatevarioslot/'+id,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
           })
}




