import { Http, HttpAuth } from '../../config/Http'
import { changeLoading } from './loading.action'
import { changeNotify } from './notify.action'

export const actionTypes = {
    CHANGE: 'ROLETA_CHANGE',
    SUCCESS: 'ROLETA_SUCCESS',
    SHOW:'ROLETA_SHOW',
    INDEX:'ROLETA_INDEX',


}

export const change = (payload) => ({
    type: actionTypes.CHANGE,
    payload
})


export const error = (payload) => ({
    type: actionTypes.ERROR,
    payload
})

export const success = (payload) => ({
    type: actionTypes.SUCCESS,
    payload
})


export const indexResponse = (payload) => ({
    type: actionTypes.INDEX,
    payload
})


export const showResponse =(payload) => ({
    type: actionTypes.SHOW,
    payload,
})

export const show = (id,botid) => dispatch => {
    return HttpAuth.get(`estrategia/showroleta/${id}/${botid}`)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}


export const index = (id) => dispatch => {
    return HttpAuth.get('estrategia/indexroleta/'+id)
    .then(res => typeof res !== 'undefined' && dispatch(indexResponse(res.data)))
}


export const update = (idbot,data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Estrategia'
    }))
    
    return HttpAuth.put(`/estrategia/updateroleta/${data.id}/${idbot}`,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
           })
}


export const reinciarbot = (id) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Status'
    }))
 
    return HttpAuth.post('grupo/reiniciarbot/'+id)
           .then(res =>{
              
                 
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                      if(res.data.error){
                          dispatch(success(false));
                          dispatch(error(res.data.error));
                      }

                      if(res.status === 201){
                        dispatch(success(false));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                          dispatch(index(id));
                         
                      }
                 }
           })
}

export const mudastatusroleta = (id,botid) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Status'
    }))
 
    return HttpAuth.put('estrategia/mudastatusroleta/'+id)
           .then(res =>{
              
                 
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                      if(res.data.error){
                          dispatch(success(false));
                          dispatch(error(res.data.error));
                      }

                      if(res.status === 201){
                        dispatch(success(false));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                          dispatch(index(botid));
                         
                      }
                 }
            })
}




