import React from 'react'
import { Dialog,DialogActions,DialogTitle,Button } from '@material-ui/core';
import {deletar} from '../../store/actions/grupo.action';
import {useDispatch} from 'react-redux';
export default function Confirm(props) {

    const {open,title,onClose,id} = props;
    const dispatch = useDispatch();



    return (
       <Dialog
         open={open}
         onClose={() => onClose()}
       >
           <DialogTitle disableTypography><h6>{title || 'Tem certeza que deseja excluir ?'}</h6></DialogTitle>
           <DialogActions className="justify-content-center mb-2">
               <Button onClick={() => onClose()}>
                   Não
               </Button>
               <Button onClick={() => {
                   onClose();
                //   dispatch(deletar(id))
                 }}
                 variant="contained"
                 color="primary"
               >
                   Sim
               </Button>

           </DialogActions>

       </Dialog>
    )
}
