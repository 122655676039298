import {actionTypes} from '../actions/roleta.action';

const initialState = {
    roletas:{
        data:[]
    },
    roleta:{
      
    },

    success:false,
    error:{},
}


export default (state = initialState, { type, payload }) => {
    switch (type) {

    case actionTypes.INDEX:
      
        return { ...state, ...payload }

    case actionTypes.SHOW:
      
        return { ...state, ...payload }
      
    case actionTypes.CHANGE:
        return{ 
            ...state,
            roleta:{
                 ...state.roleta,
                 ...payload
             }
        }

    case actionTypes.SUCCESS:
        return{ 
            ...state, 
            success: payload
         }
    case actionTypes.ERROR:
        return{
            ...state,
            error: payload
        }
    default:
        return state
    }
}
